import React, { useState, useEffect } from "react";
import "./sidebar.css";
import Logo from "../assets/images/logo/SiResto.png";

import {
  MdSpaceDashboard,
  MdChat,
  MdLoyalty,
  MdOutlineCategory,
} from "react-icons/md";
import { BsFillCartPlusFill, BsFillPersonLinesFill, BsBoxArrowInDown } from "react-icons/bs";
import { BiChevronDown, BiReceipt } from "react-icons/bi";
import { AiFillSetting, AiOutlineFileDone } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { GiTable } from "react-icons/gi";
import { TbBrandAirtable, TbDiscount } from "react-icons/tb";
import { FiCoffee, FiLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { slugify } from "../utils/strings";
import { useSelector, useDispatch } from "react-redux";
import { openSidebar, setIsMultiOutlet, setMenuMultiOutlet } from "../features/sidebarSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "../utils/strings";

const Sidebar = () => {
  const open = useSelector((state) => state.sidebar.open);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [activeMenu, setActiveMenu] = useState(
    capitalize(location.pathname.split("/").join(""))
  );
  const [showMultiOutletModal, setShowMultiOutletModal] = useState(false);
  const [showBackModal, setShowBackModal] = useState(false);

  useEffect(() => {
    const isMultiOutlet = localStorage.getItem("menuMultiOutlet") === "true";
    if (isMultiOutlet) {
      dispatch(setMenuMultiOutlet(true));
    }
  }, []);

  useEffect(() => {
    const isMultiOutlet = localStorage.getItem("menuMultiOutlet") === "true";
    const userLevel = localStorage.getItem("userLevel");
    if (isMultiOutlet) {
      dispatch(setMenuMultiOutlet(true));
    } else if (userLevel) {
      navigate(`/dashboard/${userLevel}`);
    }
  }, []);

  const MenusSuperadmin = [
    {
      title: "Dashboard",
      src: <MdSpaceDashboard className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "dashboard/superadmin",
    },
    {
      title: "Kategori Bisnis",
      src: <MdOutlineCategory className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "kategori-bisnis",
    },
    {
      title: "Resto",
      src: <FiCoffee className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "resto",
    },
    {
      title: "Group Outlet",
      src: <BsFillPersonLinesFill className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "group-outlet",
    },
    {
      title: "Setting",
      src: <AiFillSetting className={`duration-200`} size="20" />,
      type: "submenu",
      lisence: "trial",
      submenu: [
        {
          title: "Ubah Profile",
          lisence: "trial",
          link: "setting/ubah-profile",
        },
        {
          title: "Update Password",
          lisence: "trial",
          link: "setting/update-password",
        },
      ],
    },
  ];

  const MenusOwner = [
    {
      title: "Dashboard",
      src: <MdSpaceDashboard className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "dashboard/owner",
    },
    {
      title: "MultiOutlet",
      src: <BsFillPersonLinesFill className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      show: useSelector((state) => state.sidebar.isMultiOutlet),
      onClick: () => setShowMultiOutletModal(true),
    },
    {
      title: "Order",
      src: <BiReceipt className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "order",
    },
    {
      title: "Laporan",
      src: <AiOutlineFileDone className={`duration-200`} size="20" />,
      type: "submenu",
      submenu: [
        { title: "Penjualan", link: "laporan/penjualan" },
        { title: "Stok", link: "laporan/stok" },
        { title: "Mutasi Stok", link: "laporan/mutasi-stok" },
        { title: "Pendapatan", link: "laporan/pendapatan" },
      ],
    },
    {
      title: "Produk",
      src: <MdLoyalty className={`duration-200`} size="20" />,
      type: "submenu",
      lisence: "trial",
      submenu: [
        { title: "Kategori Produk", lisence: "trial", link: "kategori-produk" },
        { title: "Bahan", lisence: "trial", link: "bahan" },
        { title: "Produk Jual", lisence: "trial", link: "produk" },
      ],
    },
    {
      title: "Stok Masuk",
      src: <TbDiscount className={`duration-200`} size="20" />,
      type: "link",
      link: "stok-masuk",
      lisence: "trial",
    },
    {
      title: "Promo",
      src: <TbDiscount className={`duration-200`} size="20" />,
      type: "link",
      link: "promo",
      lisence: "trial",
    },
    {
      title: "Meja",
      src: <TbBrandAirtable className={`duration-200`} size="20" />,
      type: "link",
      link: "meja",
      lisence: "trial",
    },
    {
      title: "Supplier",
      src: <BsFillPersonLinesFill className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "supplier",
    },
    {
      title: "Staff",
      src: <BsFillPersonLinesFill className={`duration-200`} size="20" />,
      type: "link",
      link: "staff",
    },
    {
      title: "Link Pesan",
      src: <FiLink className={`duration-200`} size="20" />,
      type: "link",
      link: "setting/pesan-online",
    },
    {
      title: "Setting",
      src: <AiFillSetting className={`duration-200`} size="20" />,
      type: "submenu",
      lisence: "trial",
      submenu: [
        {
          title: "Konfigurasi Resto",
          lisence: "trial",
          link: "setting/konfigurasi-resto",
        },
        { title: "Pembayaran", lisence: "trial", link: "setting/pembayaran" },
        {
          title: "Ubah Profile",
          lisence: "trial",
          link: "setting/ubah-profile",
        },
        {
          title: "Update Password",
          lisence: "trial",
          link: "setting/update-password",
        },
      ],
    },
  ];

  const MenusOwnerReport = [
    {
      title: "Dashboard",
      src: <MdSpaceDashboard className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "dashboard/owner",
    },
    {
      title: "MultiOutlet",
      src: <BsFillPersonLinesFill className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      show: useSelector((state) => state.sidebar.isMultiOutlet),
      onClick: () => setShowMultiOutletModal(true),
    },
    {
      title: "Laporan",
      src: <AiOutlineFileDone className={`duration-200`} size="20" />,
      type: "submenu",
      submenu: [
        { title: "Penjualan", link: "laporan/penjualan" },
        { title: "Stok", link: "laporan/stok" },
        { title: "Mutasi Stok", link: "laporan/mutasi-stok" },
        { title: "Pendapatan", link: "laporan/pendapatan" },
      ],
    },
    {
      title: "Setting",
      src: <AiFillSetting className={`duration-200`} size="20" />,
      type: "submenu",
      lisence: "trial",
      submenu: [
        {
          title: "Ubah Profile",
          lisence: "trial",
          link: "setting/ubah-profile",
        },
        {
          title: "Update Password",
          lisence: "trial",
          link: "setting/update-password",
        },
      ],
    },
  ];

  const MenusStaff = [
    {
      title: "Order",
      src: <BiReceipt className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "order",
    },
    {
      title: "Laporan Penjualan",
      src: <AiOutlineFileDone className={`duration-200`} size="20" />,
      type: "link",
      link: "laporan/penjualan"
    },
    {
      title: "Setting",
      src: <AiFillSetting className={`duration-200`} size="20" />,
      type: "submenu",
      lisence: "trial",
      submenu: [
        {
          title: "Ubah Profile",
          lisence: "trial",
          link: "setting/ubah-profile",
        },
        {
          title: "Update Password",
          lisence: "trial",
          link: "setting/update-password",
        },
      ],
    },
  ];

  const handleBackToPreviousMenu = () => {
    setShowBackModal(true);
  };

  const MenusMultiOutlet = [
    {
      title: "Dashboard",
      src: <MdSpaceDashboard className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      link: "dashboard/multioutlet",
    },
    {
      title: "Laporan",
      src: <AiOutlineFileDone className={`duration-200`} size="20" />,
      type: "submenu",
      submenu: [
        { title: "Penjualan", link: "laporan/penjualanMultiOutlet" },
        { title: "Stok", link: "laporan/stokMultiOutlet" },
        { title: "Mutasi Stok", link: "laporan/mutasi-stokMultiOutlet" },
        { title: "Pendapatan", link: "laporan/pendapatanMultiOutlet" },
      ],
    },
    {
      title: "Kembali",
      src: <FaTimes className={`duration-200`} size="20" />,
      type: "link",
      lisence: "trial",
      onClick: handleBackToPreviousMenu,
    },
  ];

  const menuMultiOutlet = useSelector((state) => state.sidebar.menuMultiOutlet);

  const pilihMenu = () => {
    if (menuMultiOutlet) {
      return MenusMultiOutlet;
    }
    if (user?.level) {
      switch (user.level) {
        case "Superadmin":
          return MenusSuperadmin;
        case "Owner":
          return MenusOwner.filter((menu) => menu.show !== false);
        case "OwnerReport":
          return MenusOwnerReport.filter((menu) => menu.show !== false);
        case "Staff":
          return MenusStaff;
        default:
          return [];
      }
    }
    return [];
  };

  const settingItemMenu = (menu) => {
    setActiveMenu(menu.title);

    if (menu.type == "submenu") {
      document
        .getElementById(`nav-submenu-${slugify(menu.title)}`)
        .classList.toggle("hidden");
      document
        .getElementById(`arrow-submenu-${slugify(menu.title)}`)
        .classList.toggle("rotate-180");
    } else {
      navigate(`/${menu.link}`);
      dispatch(openSidebar());
    }
  };

  const handleMultiOutletChange = () => {
    dispatch(setMenuMultiOutlet(true));
    dispatch(setIsMultiOutlet(true));
    localStorage.setItem("menuMultiOutlet", "true"); // Simpan status
    setShowMultiOutletModal(false);
    navigate("/dashboard/multioutlet");
  };

  const handleCancel = () => {
    setShowMultiOutletModal(false);
  };

  const handleCancelBack = () => {
    setShowBackModal(false);
  };

  const handleConfirmBack = () => {
    setShowBackModal(false);
    dispatch(setMenuMultiOutlet(false));
    localStorage.setItem("menuMultiOutlet", "false");
    navigate("/dashboard/" + user?.level);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };


  const Menus = pilihMenu();

  const menusLayout = Menus.map((menu, index) => {
    if (menu.lisence == "premium" && user?.lisence.toLowerCase() != "premium") {
      return;
    }

    return (
      <li
        key={index}
        className={`text-sm cursor-pointer py-2 duration-500 `}
        onClick={() => {
          if (menu.onClick) {
            menu.onClick();
          } else {
            settingItemMenu(menu);
            dispatch(openSidebar);
          }
        }}

      >
        <div
          className={`flex gap-x-3 hover:text-blue-500 duration-100 py-1 px-6 mx-1 mb-2 ${activeMenu === menu.title ? "text-blue-500" : "text-slate-600"
            } items-center`}
        >
          {menu.src}
          {menu.type == "submenu" ? (
            <span className={`flex justify-between flex-1`}>
              {menu.title}{" "}
              <BiChevronDown
                size="20"
                className="duration-300"
                id={`arrow-submenu-${slugify(menu.title)}`}
              />
            </span>
          ) : (
            <span className={`duration-300 flex justify-between`}>
              {menu.title}
            </span>
          )}
        </div>
        {menu.type == "submenu" && (
          <ul
            id={`nav-submenu-${slugify(menu.title)}`}
            className="duration-500 hidden py-2 space-y-2"
          >
            {menu.submenu.map((item, key) => {
              if (
                item.lisence == "premium" &&
                user?.lisence.toLowerCase() != "premium"
              ) {
                return;
              }

              return (
                <li
                  key={key}
                  className={`duration-500 text-sm cursor-pointer hover:text-blue-500 duration-100 py-2 px-8 mx-1 mb-1 text-slate-600`}
                  onClick={() => settingItemMenu(item)}
                >
                  <span className="origin-left w-full text-gray-900 transition duration-75">
                    {item.title}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    );
  });

  return (
    <React.Fragment>
      <div
        className={`${open ? "w-full" : "w-0"
          } overflow-hidden lg:w-64 absolute top-0 left-0 z-20 lg:relative duration-300 mt-14 lg:mt-0 bg-white mr-1 border-r border-slate-200`}
      >
        <div className="hidden lg:flex px-4 items-center justify-center items-center border-b border-border-light h-16">
          <img src={Logo} alt={Logo} className="w-24" />
        </div>
        <div className="overflow-y-hidden hover:overflow-y-auto h-screen pb-16">
          <ul className="pt-4">{menusLayout}</ul>
        </div>
      </div>

      {showMultiOutletModal && (
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-10"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-90 max-w-lg p-4 mx-auto bg-white rounded-xl shadow-lg">
              <div className="mt-3 sm:flex">
                <div className="flex items-center justify-center flex-none w-5 h-5 mx-auto mt-2 mr-3 bg-blue-100 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-blue-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="mt-1 mx-auto">
                  <h4 className="text-lg text-left font-medium text-gray-800">
                    Konfirmasi Pindah User
                  </h4>
                  <p className="mt-2 text-left text-[15px] leading-relaxed text-gray-500">
                    Apakah Anda yakin ingin beralih ke pengguna Multi-Outlet?
                  </p>
                  <div className="items-center gap-2 mt-4 sm:flex">
                    <button
                      className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-white bg-blue-600 rounded-md outline-none ring-offset-2 ring-blue-600 focus:ring-2"
                      onClick={handleMultiOutletChange}
                    >
                      Pindah
                    </button>
                    <button
                      className="w-full h-10 px-12 py-1 mt-2 mr-6 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-gray-600 focus:ring-2"
                      onClick={handleCancel}
                    >
                      Batal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showBackModal && (
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-10"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-90 max-w-lg p-4 mx-auto bg-white rounded-xl shadow-lg">
              <div className="mt-3 sm:flex">
                <div className="flex items-center justify-center flex-none w-5 h-5 mx-auto mt-2 mr-3 bg-red-100 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-red-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="mt-1 mx-auto">
                  <h4 className="text-lg text-left font-medium text-gray-800">
                    Konfirmasi Kembali
                  </h4>
                  <p className="mt-2 text-left text-[15px] leading-relaxed text-gray-500">
                    Apakah Anda yakin ingin kembali ke menu sebelumnya?
                  </p>
                  <div className="items-center gap-2 mt-4 sm:flex">
                    <button
                      className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-white bg-blue-600 rounded-md outline-none ring-offset-2 ring-blue-600 focus:ring-2"
                      onClick={handleConfirmBack}
                    >
                      Ya
                    </button>
                    <button
                      className="w-full h-10 px-12 py-1 mt-2 mr-6 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-gray-600 focus:ring-2"
                      onClick={handleCancelBack}
                    >
                      Batal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </React.Fragment>
  );
};

export default Sidebar;
