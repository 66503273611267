import React, { useState, useEffect } from "react";

// components
import CardCount from "../../../components/CardCount";
import ChartBar from "../../../components/ChartBarMultiOutlet";
import LoadingPage from "../../../components/LoadingPage";

// icons
import { MdSpaceDashboard, MdLoyalty } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { BiReceipt } from "react-icons/bi";
import { BsFillPersonLinesFill } from "react-icons/bs";

// libraries
import { useQuery } from "react-query";
import axios from "../../../utils/axios";

const DashboardMultiOutlet = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const name = user?.name;
  const token = user?.token;

  const [selectedResto, setSelectedResto] = useState("total"); // State untuk filter resto
  const [chartData, setChartData] = useState([]);
  const [filteredData, setFilteredData] = useState({
    penjualan_hari_ini: 0,
    jumlah_produk: 0,
    pendapatan_hari_ini: 0,
    total_staff: 0,
  });

  // Fetch data
  const fetchData = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.get("dashboard/multi_outlet");
    return response.data;
  };

  // Fetch data dashboard
  const { isLoading, data, isSuccess, refetch } = useQuery(
    ["data-dashboard"],
    fetchData,
    {
      keepPreviousData: true,
    }
  );

  // Handle perubahan filter resto
  const handleFilterChange = (event) => {
    const value = event.target.value;
    setSelectedResto(value);

    if (value === "total") {
      setFilteredData({
        penjualan_hari_ini: data?.penjualan_hari_ini || 0,
        jumlah_produk: data?.jumlah_produk || 0,
        pendapatan_hari_ini: data?.pendapatan_hari_ini || 0,
        total_staff: data?.total_staff || 0,
      });
      setChartData(data?.data_penjualan_per_bulan || []);
    } else {
      const restoData = data?.data_penjualan_per_resto.find(
        (resto) => resto.id_resto === parseInt(value)
      );
      setFilteredData({
        penjualan_hari_ini: restoData?.penjualan_hari_ini || 0,
        jumlah_produk: restoData?.jumlah_produk || 0,
        pendapatan_hari_ini: restoData?.pendapatan_hari_ini || 0,
        total_staff: restoData?.total_staff || 0,
      });
      setChartData(restoData?.penjualan_per_bulan || []);
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setFilteredData({
        penjualan_hari_ini: data?.penjualan_hari_ini || 0,
        jumlah_produk: data?.jumlah_produk || 0,
        pendapatan_hari_ini: data?.pendapatan_hari_ini || 0,
        total_staff: data?.total_staff || 0,
      });
      setChartData(data?.data_penjualan_per_bulan || []);
    }
  }, [isSuccess, data]);

  if (isLoading) {
    return (
      <div className="flex-1 flex justify-center items-center flex-col space-y-3">
        <LoadingPage />
      </div>
    );
  }

  return (
    <div className="px-6 pb-6">
      <h2 className="text-xl mt-4 mb-6 font-semibold">Hey, {name}</h2>
      <div className="grid gap-6 md:grid-cols-4 grid-cols-1 grid-rows-1">
        <CardCount
          title="Penjualan Hari Ini"
          value={filteredData.penjualan_hari_ini}
          icon={<BiReceipt size={20} className="text-lg text-blue-500" />}
          color="blue"
        />
        <CardCount
          title="Jumlah Produk"
          value={filteredData.jumlah_produk}
          icon={<MdLoyalty size={20} className="text-lg text-blue-500" />}
          color="blue"
        />
        <CardCount
          title="Pendapatan Hari Ini"
          value={filteredData.pendapatan_hari_ini}
          icon={<FiShoppingCart size={20} className="text-lg text-blue-500" />}
          color="blue"
        />
        <CardCount
          title="Total Staff"
          value={filteredData.total_staff}
          icon={
            <BsFillPersonLinesFill
              size={20}
              className="text-lg text-blue-500"
            />
          }
          color="blue"
        />
      </div>
      <h3 className="text-md mt-6 font-semibold">Monitoring</h3>
      <div className="grid grid-cols-12 mt-5 gap-4 md:mb-4">
        <div className="md:col-span-8 col-span-12 bg-white items-center rounded-3xl border border-gray-300">
          <div className="border-b border-gray-200 mb-1 py-2 px-6 flex justify-between items-center">
            <h5 className="text-sm font-semibold">Statistik Pendapatan</h5>
            <select
              className="border border-gray-300 rounded-md px-2 py-1 text-sm"
              value={selectedResto}
              onChange={handleFilterChange}
            >
              <option value="total">Semua Resto</option>
              {data?.data_penjualan_per_resto?.map((resto) => (
                <option key={resto.id_resto} value={resto.id_resto}>
                  {resto.nama_resto}
                </option>
              ))}
            </select>
          </div>
          <div className="p-3 pt-3">
            <ChartBar data={chartData} title="Pendapatan Per Bulan" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMultiOutlet;