import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        open: false,
        isMultiOutlet: false,
        menuMultiOutlet: false,
    },
    reducers: {
        openSidebar: (state, action) => {
            state.open = !state.open
        },
        setIsMultiOutlet: (state, action) => {
            state.isMultiOutlet = action.payload
        },
        setMenuMultiOutlet: (state, action) => {
            state.menuMultiOutlet = action.payload
        }
    }
})

export const { openSidebar, setIsMultiOutlet, setMenuMultiOutlet } = sidebarSlice.actions
export default sidebarSlice.reducer