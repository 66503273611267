import React, { useState, useEffect, useRef, useCallback } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./laporan.css";

// components
import { Button } from "../../../components/Button";
import HeaderContent from "../../../layouts/HeaderContent";
import { LaporanPenjualanMultiOutlet } from "./Cetak/penjualanMultiOutlet";

// icons
import { BsFillPrinterFill } from "react-icons/bs";

// libraries
import axios from "../../../utils/axios";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const PenjualanMultiOutlet = () => {
  moment.locale("id");

  const user = JSON.parse(localStorage.getItem("user"));
  const [tanggalAwal, setTanggalAwal] = useState(new Date());
  const [tanggalAkhir, setTanggalAkhir] = useState(new Date());
  const [statusOrder, setStatusOrder] = useState("");
  const [listRestoran, setListRestoran] = useState([]); // Daftar restoran
  const [selectedResto, setSelectedResto] = useState(""); // Resto yang dipilih
  const [data, setData] = useState([]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setData([]),
  });

  const breadcrumbs = [
    { link: "/laporan", menu: "Laporan" },
    { link: "/penjualan", menu: "Laporan Penjualan Multi Outlet" },
  ];

  const fetchRestoran = useCallback(async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

    try {
      const response = await axios.get("/laporan/resto-group");
      const res = response.data.data;

      setListRestoran(res);
    } catch (error) {
      console.error("Error fetching restoran:", error);
    }
  }, [user.token]);

  const fetchOrder = useCallback(async () => {
    const tanggalAwalFormat = moment(tanggalAwal).format("DD-MM-YYYY");
    const tanggalAkhirFormat = moment(tanggalAkhir).format("DD-MM-YYYY");

    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

    try {
      const params = {
        'tanggal-awal': tanggalAwalFormat,
        'tanggal-akhir': tanggalAkhirFormat,
        'status-order': statusOrder,
      };

      // Jika selectedResto tidak kosong, tambahkan id-resto ke params
      if (selectedResto) {
        params['id-resto'] = selectedResto;
      }

      const response = await axios.get('laporan/laporan-penjualan-group', { params });
      const res = response.data.data;

      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    }
  }, [tanggalAwal, tanggalAkhir, statusOrder, selectedResto, user.token]);

  const handleStatusOrderChange = (e) => {
    setStatusOrder(e.target.value);
  };

  useEffect(() => {
    fetchRestoran();
  }, [fetchRestoran]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  return (
    <>
      <HeaderContent title="Laporan Penjualan Multi Outlet" breadcrumbs={breadcrumbs} />
      <div className="bg-white h-max px-6 rounded-lg mt-4 grid grid-cols-12 gap-x-3.5">
        <div className="col-span-2">
          <DatePicker
            onChange={setTanggalAwal}
            value={tanggalAwal}
            className="input input-bordered outline-0 border-blue-500 text-gray-900 text-sm focus:ring-blue-500 block w-full"
          />
        </div>
        <div className="col-span-2">
          <DatePicker
            onChange={setTanggalAkhir}
            value={tanggalAkhir}
            className="input input-bordered outline-0 border-blue-500 text-gray-900 text-sm focus:ring-blue-500 block w-full"
          />
        </div>
        <div className="col-span-2">
          <select
            onChange={handleStatusOrderChange}
            className="input input-bordered outline-0 border-blue-500 text-gray-900 text-sm focus:ring-blue-500 block w-full focus:outline-none"
          >
            <option value="">Semua</option>
            <option value="open">Buka</option>
            <option value="in_progress">Dalam Proses</option>
            <option value="closed">Tutup</option>
          </select>
        </div>
        <div className="col-span-2">
          <select
            value={selectedResto}
            onChange={(e) => setSelectedResto(e.target.value)}
            className="input input-bordered outline-0 border-blue-500 text-gray-900 text-sm focus:ring-blue-500 block w-full"
          >
            <option value="">Semua Restoran</option>
            {listRestoran.map((resto) => (
              <option key={resto.id_resto} value={resto.id_resto}>
                {resto.nama_resto}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-3">
          <Button
            className="text-xs"
            color="primary"
            type="button"
            startIcon={<BsFillPrinterFill size={16} />}
            loading={false}
            title="Cetak Laporan"
            onClick={(e) => {
              e.preventDefault(); // Mencegah refresh
              handlePrint(); // Panggil handlePrint untuk mencetak
            }}
          />
        </div>
      </div>

      <div>
        <LaporanPenjualanMultiOutlet
          ref={componentRef}
          tanggalAwal={tanggalAwal}
          tanggalAkhir={tanggalAkhir}
          data={data}
          name={user?.name}
        />
      </div>
    </>
  );
};

export default PenjualanMultiOutlet;
