import React, { useState, useEffect, useRef, useCallback } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./laporan.css";

// components
import { Button } from "../../../components/Button";
import HeaderContent from "../../../layouts/HeaderContent";
import { LaporanPendapatanMultiOutlet } from "./Cetak/pendapatanMultiOutlet";

// icons
// import { HiPencil } from "react-icons/hi";
import {
  // BsFillTrash2Fill,
  // BsFillPlusCircleFill,
  BsFillPrinterFill,
} from "react-icons/bs";
// import { MdPublishedWithChanges } from "react-icons/md";
// import { FiPrinter } from "react-icons/fi";
// import { BiSearch, BiPlus, BiDotsHorizontalRounded } from "react-icons/bi";
// import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";

// libraries
// import { Link } from "react-router-dom";
// import { useQuery } from "react-query";
import axios from "../../../utils/axios";
// import { swNormal, swConfirm } from "../../../utils/sw";
// import { useMutation, QueryClient } from "react-query";
// import { useNavigate } from "react-router-dom";
// import { useForm, Controller } from "react-hook-form";
// import QRCode from "react-qr-code";
// import { baseUrlFrontEnd } from "../../../utils/strings";
// ReactToPrint,
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const PendapatanMultiOutlet = () => {
  moment.locale("id");
  const user = JSON.parse(localStorage.getItem("user"));
  const [tanggalAwal, setTanggalAwal] = useState(new Date());
  const [tanggalAkhir, setTanggalAkhir] = useState(new Date());
  const [data, setData] = useState({
    penjualanBersih: 0,
    hpp: 0,
    labaBersih: 0,
    labaKotor: 0,
    penjualanKotor: 0,
    totalDiskon: 0,
    pajak: 0,
    serviceCharge: 0,
  });

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Laporan Pendapatan Multi Outlet",
    onAfterPrint: () => console.log("Dokumen berhasil dicetak!"),
    removeAfterPrint: true,
  });  

  const breadcrumbs = [
    { link: "/laporan", menu: "Laporan" },
    { link: "/pendapatan", menu: "Laporan Pendapatan" },
  ];
  const [listRestoran, setListRestoran] = useState([]);
  const [selectedResto, setSelectedResto] = useState("");

  // Fetch daftar restoran
  const fetchRestoran = useCallback(async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

    try {
      const response = await axios.get("/laporan/resto-group");
      const res = response.data.data;

      setListRestoran(res);
    } catch (error) {
      console.error("Error fetching restoran:", error);
    }
  }, [user.token]);

  // Fetch data laporan pendapatan
  const fetchOrder = useCallback(async () => {
    let tanggalAwalFormat = moment(tanggalAwal).format("YYYY-MM-DD 00:00:00");
    let tanggalAkhirFormat = moment(tanggalAkhir).format("YYYY-MM-DD 23:59:59");

    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

    try {
      const response = await axios.get(
        `/laporan/laporan-pendapatan-group?tanggal-awal=${tanggalAwalFormat}&tanggal-akhir=${tanggalAkhirFormat}${
          selectedResto ? `&id_resto=${selectedResto}` : ""
        }`
      );
      const res = response.data.data;

      setData({
        penjualanBersih: res.penjualan_bersih,
        hpp: res.hpp,
        labaBersih: res.laba_bersih,
        labaKotor: res.laba_kotor,
        penjualanKotor: res.penjualan_kotor,
        totalDiskon: res.total_diskon,
        pajak: res.pajak,
        serviceCharge: res.service_charge,
      });
    } catch (error) {
      console.error("Error fetching laporan pendapatan:", error);
    }
  }, [tanggalAwal, tanggalAkhir, selectedResto, user.token]);

  useEffect(() => {
    fetchRestoran();
  }, [fetchRestoran]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  return (
    <>
      <HeaderContent title="Laporan Pendapatan" breadcrumbs={breadcrumbs} />
      <div className="bg-white h-max px-6 rounded-lg mt-4 grid grid-cols-8 gap-x-3.5">
        <div className="col-span-2">
          <DatePicker
            onChange={setTanggalAwal}
            value={tanggalAwal}
            className="input input-bordered outline-0 border-blue-500 text-gray-900 text-sm focus:ring-blue-500 block w-full"
          />
        </div>
        <div className="col-span-2">
          <DatePicker
            onChange={setTanggalAkhir}
            value={tanggalAkhir}
            className="input input-bordered outline-0 border-blue-500 text-gray-900 text-sm focus:ring-blue-500 block w-full"
          />
        </div>
        <div className="col-span-1">
          <select
            value={selectedResto}
            onChange={(e) => setSelectedResto(e.target.value)}
            className="input input-bordered outline-0 border-blue-500 text-gray-900 text-sm focus:ring-blue-500 block w-full"
          >
            <option value="">Semua Restoran</option>
            {listRestoran.map((resto) => (
              <option key={resto.id_resto} value={resto.id_resto}>
                {resto.nama_resto}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-3">
          <Button
            className="text-xs"
            color="primary"
            type="button"
            startIcon={<BsFillPrinterFill size={16} />}
            loading={false}
            title="Cetak Laporan"
            onClick={handlePrint}
          />
        </div>
      </div>

      <div className="py-4">
        <LaporanPendapatanMultiOutlet
          ref={componentRef}
          tanggalAwal={tanggalAwal}
          tanggalAkhir={tanggalAkhir}
          data={data}
        />
      </div>
    </>
  );
};

export default PendapatanMultiOutlet;
